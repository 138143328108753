.border-camera {
  --b: 4px; /* thickness of the border */
  --c: #4ee44e; /* color of the border */
  --w: 30px; /* width of border */

  border: var(--b) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);

  /*Irrelevant code*/
  width: 90%;
  height: 90%;
  left: 5%;
  top: calc(5% - 2px);
  box-sizing: border-box;
  display: inline-flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.custom-opt-code {
  gap: 12px !important;
  margin: auto;
  input {
    font-size: 18px;
    padding: 12px;
    font-weight: bold;
  }
}

.slick-active {
  svg {
    color: var(--black-01);
  }
}
.slick-dots {
  li {
    color: var(--gray-400);
  }
}
.date_class {
  border-color: var(--grey-500);
}

.ellipsis-2line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.area-error {
  border-color: var(--red-01) !important;
}

.required::after {
  content: '*';
  color: var(--red-01);
}

*::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--gray-400) !important;
}
*::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--gray-400) !important;
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--gray-400) !important;
}
*:-moz-placeholder {
  /* Firefox 18- */
  color: var(--gray-400) !important;
}
.MuiChip-colorDefault {
  background-color: var(--gray-03) !important;
}
.Mui-disabled.MuiButton-root.MuiButton-contained {
  box-shadow: none !important;
}
.MuiChip-root {
  border-radius: 6px !important; 
}
.MuiContainer-root{
  padding-top: 24px !important;
}
.MuiButtonBase-root.MuiTab-root{
  min-width: 0px !important;
  min-height: 0px !important;
  padding: 6px 8px !important;
}
.ql-editor{
  font-size: 16px !important;
}